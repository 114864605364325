import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

function MessageAlert({ message, open, onClose, onNeverShowAgain }) {
  if (!message) return null;

  const neverShowButton = message.forceActive ? (
    <Tooltip title="Questo messaggio è importante e non può essere nascosto" placement="top">
      <span>
        <Button
          disabled
          sx={{
            color: "rgba(74, 85, 104, 0.5)",
            cursor: "not-allowed",
          }}
        >
          Non mostrare più
        </Button>
      </span>
    </Tooltip>
  ) : (
    <Button
      onClick={onNeverShowAgain}
      sx={{
        color: "#4a5568",
        "&:hover": {
          background: "rgba(74, 85, 104, 0.08)",
        },
      }}
    >
      Non mostrare più
    </Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          background: "#ffffff",
          borderRadius: "16px",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        sx={{
          background: "#f8f9fa",
          padding: "24px",
          display: "flex",
          alignItems: "center",
          gap: 2,
          borderBottom: "1px solid #edf2f7",
        }}
      >
        <InfoIcon sx={{ color: "#4c79d8", fontSize: 28 }} />
        <Typography
          variant="h5"
          component="div"
          sx={{
            fontWeight: 600,
            color: "#2d3748",
            fontSize: "1.25rem",
            lineHeight: 1.4,
          }}
        >
          {message.title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "24px",
          background: "#ffffff",
        }}
      >
        <Box
          sx={{
            "& p": {
              margin: "0 0 16px",
              color: "#4a5568",
              lineHeight: 1.6,
              fontSize: "1rem",
            },
            "& strong": {
              color: "#2d3748",
              fontWeight: 600,
            },
            "& a": {
              color: "#4c79d8",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            },
            background: "#ffffff",
          }}
          dangerouslySetInnerHTML={{ __html: message.html }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          padding: "16px 24px",
          background: "#f8f9fa",
          borderTop: "1px solid #edf2f7",
          gap: 2,
        }}
      >
        {neverShowButton}
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            background: "#4c79d8",
            color: "#ffffff",
            textTransform: "none",
            fontWeight: 600,
            padding: "8px 24px",
            "&:hover": {
              background: "#3b62c0",
            },
          }}
        >
          Ho capito
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MessageAlert;
