import axios from "axios";
import { PlanData } from "../components/priceContainer/PlanData";

// export const statusCheck = async (email) => {
//   //#region  verfication of status subscrtion
//   try {
//     const res = await axios.get(
//       // `${process.env.REACT_APP_MY_DOMAIN_SERVER}/status?email=${email}`
//       `https://server-easyscratch.oa.r.appspot.com/status?email=${email}`
//     );

//     const resFindPlans = [];
//     res.data.data.forEach((plans) => {
//       if (PlanData.some((item) => item["path"] === plans.items.data[0].price.id)) {
//         resFindPlans.push(plans.status);
//       }
//     });

//     if (
//       resFindPlans.includes("unpaid") ||
//       resFindPlans.includes("canceled") ||
//       resFindPlans.includes("incomplete") ||
//       resFindPlans.includes("incomplete_expired") ||
//       resFindPlans.includes("ended") ||
//       resFindPlans.length === 0
//     ) {
//       return {
//         status: false,
//         message: `Abbonamento scaduto
//           effetturare il rinnovo
//           o contattare il gestore`,
//       };
//     } else {
//       return {
//         status: true,
//         message: `abbonamento valido, OK`,
//       };
//     }
//   } catch (error) {
//     console.log(error.message);
//     console.log(error.response.data);
//     return {
//       status: false,
//       message: `${error.response.data}
//           ${error.message}`,
//     };
//   }
//   //#endregion
// };

// export const statusCheck = async (email) => {
//   try {
//     const res = await axios.get(`https://server-easyscratch.oa.r.appspot.com/status?email=${email}`);

//     const resFindPlans = [];
//     console.log("res", res);
//     res.data.data.forEach((plans) => {
//       if (PlanData.some((item) => item["path"] === plans.items.data[0].price.id)) {
//         resFindPlans.push({
//           status: plans.status,
//           endedAt: plans.ended_at,
//           canceledAt: plans.canceled_at,
//         });
//       }
//     });

//     if (
//       resFindPlans.length === 0 ||
//       resFindPlans.every(
//         (plan) =>
//           plan.status === "unpaid" ||
//           plan.status === "canceled" ||
//           plan.status === "incomplete" ||
//           plan.status === "incomplete_expired" ||
//           plan.status === "ended"
//       )
//     ) {
//       const latestEndDate = resFindPlans.reduce((latest, plan) => {
//         const endDate = plan.endedAt || plan.canceledAt || null;
//         if (!latest || (endDate && new Date(endDate) > new Date(latest))) {
//           return endDate;
//         }
//         return latest;
//       }, null);

//       if (!latestEndDate) {
//         return {
//           status: false,
//           message: "Abbonamento non attivo. Effettua il rinnovo per continuare a utilizzare il servizio.",
//         };
//       }

//       const endDate = new Date(latestEndDate * 1000);
//       const now = new Date();
//       const diffDays = Math.ceil((endDate.getTime() + 30 * 24 * 60 * 60 * 1000 - now.getTime()) / (1000 * 60 * 60 * 24));

//       if (diffDays <= 0) {
//         return {
//           status: false,
//           message: "Il periodo di grazia è terminato. Effettua il rinnovo per continuare a utilizzare il servizio.",
//         };
//       }

//       return {
//         status: true,
//         message: `Il tuo abbonamento è scaduto. Hai ${diffDays} ${
//           diffDays === 1 ? "giorno" : "giorni"
//         } per rinnovarlo prima che l'accesso venga disattivato.`,
//       };
//     }

//     return {
//       status: true,
//       message: "Abbonamento attivo",
//     };
//   } catch (error) {
//     console.log(error.message);
//     console.log(error.response?.data);
//     return {
//       status: false,
//       message: `${error.response?.data || "Errore durante la verifica dell'abbonamento"}\n${error.message}`,
//     };
//   }
// };

// Codici di stato dell'abbonamento
export const SUBSCRIPTION_STATUS = {
  ACTIVE: "ACTIVE", // Abbonamento attivo e non in scadenza
  EXPIRING_SOON: "EXPIRING_SOON", // Abbonamento attivo ma in scadenza
  EXPIRED: "EXPIRED", // Abbonamento scaduto
  NO_SUBSCRIPTION: "NO_SUBSCRIPTION", // Nessun abbonamento trovato
  ERROR: "ERROR", // Errore durante la verifica
};

export const statusCheck = async (email) => {
  try {
    const res = await axios.get(`https://server-easyscratch.oa.r.appspot.com/status?email=${email}`);
    const resFindPlans = [];

    if (!res.data.data || !Array.isArray(res.data.data)) {
      return {
        status: false,
        code: SUBSCRIPTION_STATUS.ERROR,
        message: "Errore nel formato dei dati ricevuti dal server",
        details: null,
      };
    }

    res.data.data.forEach((plans) => {
      if (PlanData.some((item) => item["path"] === plans.items.data[0].price.id)) {
        resFindPlans.push({
          status: plans.status,
          cancelAt: plans.cancel_at,
          currentPeriodEnd: plans.current_period_end,
          planName: plans.items.data[0].price.nickname || "Piano standard",
        });
      }
    });

    // Se non ci sono piani attivi
    if (resFindPlans.length === 0) {
      return {
        status: false,
        code: SUBSCRIPTION_STATUS.NO_SUBSCRIPTION,
        message: "Nessun abbonamento attivo",
        details: {
          action: "subscribe",
          severity: "high",
        },
      };
    }

    // Trova il piano attivo più recente
    const activePlan = resFindPlans.find((plan) => plan.status === "active");
    if (!activePlan) {
      return {
        status: false,
        code: SUBSCRIPTION_STATUS.EXPIRED,
        message: "Abbonamento scaduto",
        details: {
          action: "renew",
          severity: "high",
          planName: resFindPlans[0].planName,
        },
      };
    }

    // Calcola i giorni rimanenti
    const expirationDate = new Date(activePlan.currentPeriodEnd * 1000);
    const now = new Date();
    const daysUntilExpiration = Math.ceil((expirationDate - now) / (1000 * 60 * 60 * 24));

    // Se mancano 30 giorni o meno alla scadenza
    if (daysUntilExpiration <= 30) {
      return {
        status: true,
        code: SUBSCRIPTION_STATUS.EXPIRING_SOON,
        message: "Abbonamento in scadenza",
        details: {
          daysRemaining: daysUntilExpiration,
          expirationDate: expirationDate.toISOString(),
          planName: activePlan.planName,
          action: "renew",
          severity: "medium",
        },
      };
    }

    // Abbonamento attivo e non in scadenza
    return {
      status: true,
      code: SUBSCRIPTION_STATUS.ACTIVE,
      message: "Abbonamento attivo",
      details: {
        daysRemaining: daysUntilExpiration,
        planName: activePlan.planName,
        expirationDate: expirationDate.toISOString(),
        severity: "low",
      },
    };
  } catch (error) {
    console.error("Errore durante la verifica dell'abbonamento:", error);
    return {
      status: false,
      code: SUBSCRIPTION_STATUS.ERROR,
      message: "Errore durante la verifica dell'abbonamento",
      details: {
        error: error.message,
        serverError: error.response?.data,
        severity: "high",
      },
    };
  }
};
