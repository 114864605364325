import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import * as TiIcons from "react-icons/ti";
import * as FiIcons from "react-icons/fi";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";

import { BiBarcodeReader } from "react-icons/bi";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "../styles/Home.css";
import { useDispatch, useSelector } from "react-redux";
import {
  scarica,
  eliminaVendita,
  fetchDayTotal,
  incassa,
  errorReset,
  stornaBiglietto,
  scaricaMazzetta,
  mostraVendite,
} from "../store/actions/handleHome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { stateChange } from "../functions/stateChange";
import { checkMessage } from "../functions/Message";
import { DataTranform } from "../functions/DateTranform";
import ReactTooltip from "react-tooltip";
import SpinnerCarrello from "../components/SpinnerCarrello";
import SpinnerTotG from "../components/SpinnerTotG";
import { CeckConnection } from "../functions/ConnectionStatius";
import CarSpinner from "../components/CarSpinner";
import soudError from "../sound/error.wav";
import Navbar from "../components/Navbar";
import "../styles/Price.css";
import "../styles/Price.scss";
import {
  afterstornoTourStart,
  homeSearchBarTourStart,
  homeTourStart,
  incassaStart,
  stornoTourStart,
} from "../store/actions/handleTour";
import { statusCheck, SUBSCRIPTION_STATUS } from "../functions/statusCheck";
import { logoutRoot } from "../store/actions/handelAuth";
import MessageAlert from "../components/MessageAlert";
import { collection, getDocs } from "firebase/firestore";
import db from "../Firebase-config";
//dynamic tour component import
// import { logoutRoot } from "../store/actions/handelAuth";
// import { statusCheck } from "../functions/statusCheck";
// var CronJob = require("cron").CronJob;

registerLocale("it", it);

//#endregion
function Home() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState("");
  const [daySelected, setDaySelected] = useState(startDate.toLocaleDateString().replaceAll("/", "-"));

  const [showMessage, setShowMessage] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);

  const [subscriptionMessage, setSubscriptionMessage] = useState(null);
  const [message, setMessage] = useState("");

  const uiVendita = useSelector((state) => state.homeReducers.uiVendita);

  const totCarrello = useSelector((state) => state.homeReducers.tot);
  const totaleGiornaliero = useSelector((state) => state.homeReducers.totaleGiornaliero);
  const error = useSelector((state) => state.homeReducers.error);
  // const message = useSelector((state) => state.homeReducers.message);
  const loading = useSelector((state) => state.homeReducers.loading);
  const loadingIncassa = useSelector((state) => state.homeReducers.loadingIncassa);
  const arrVenditeDay = useSelector((state) => state.homeReducers.arrVenditeDay);
  const {
    startIntroTour,

    startCaricaGrattaTour,

    startStornoTour,
  } = useSelector((state) => state.tourReducer);

  const audio = new Audio(soudError);
  audio.loop = false;

  const playError = () => {
    audio.loop = false;
    audio.play();
  };
  const notifyError = () =>
    toast.error(message, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  //#region CLOCK SCHEDULE
  // */1 * * * * evrey  five minutes
  // 0 10 * * *  every day at 10 am
  // var controlSubscription = new CronJob("0 10 * * *", async function () {
  //   // control if subscrition is active or trialing
  //   // if is not acrive or trialing dispatch login functions
  //   const resStaSubscription = await statusCheck(email);
  //   console.log(resStaSubscription);
  //   if (!resStaSubscription.status) {
  //     dispatch(logoutRoot());
  //     window.location.reload();
  //     return;
  //   }
  //   // ------
  // });
  // controlSubscription.start();
  //#endregion

  const createSubscriptionMessage = (response) => {
    let messageTemplate = '<div style="text-align: center; font-family: Arial, sans-serif;">';

    // Icona di warning
    messageTemplate += `
      <div style="margin-bottom: 15px;">
        <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZjk4MDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cGF0aCBkPSJNMTAgMTNoNHYzaC00eiI+PC9wYXRoPjxwYXRoIGQ9Ik0xMiAyYTEwIDEwIDAgMSAwIDAgMjAgMTAgMTAgMCAxIDAgMC0yMHoiPjwvcGF0aD48cGF0aCBkPSJNMTIgNnYzIj48L3BhdGg+PC9zdmc+" 
        alt="warning" style="width: 64px; height: 64px;" />
      </div>`;

    // Titolo e contenuto in base al codice di stato
    switch (response.code) {
      case SUBSCRIPTION_STATUS.EXPIRING_SOON:
        const days = response.details.daysRemaining;
        messageTemplate += `
          <div style="margin-bottom: 20px; color: #e65100; font-size: 20px; font-weight: bold;">
            Il tuo abbonamento sta per scadere
          </div>
          <div style="margin-bottom: 15px; color: #424242; font-size: 16px;">
            Mancano <span style="color: #ff5722; font-weight: bold; font-size: 20px;">
            ${days} ${days === 1 ? "giorno" : "giorni"}</span> alla scadenza.
          </div>`;
        break;

      case SUBSCRIPTION_STATUS.EXPIRED:
        messageTemplate += `
          <div style="margin-bottom: 20px; color: #d32f2f; font-size: 20px; font-weight: bold;">
            Il tuo abbonamento è scaduto
          </div>
          <div style="margin-bottom: 15px; color: #424242; font-size: 16px;">
            Il tuo abbonamento è scaduto.
            <br>Rinnova ora per continuare ad utilizzare tutti i servizi.
          </div>`;
        break;

      case SUBSCRIPTION_STATUS.NO_SUBSCRIPTION:
        messageTemplate += `
          <div style="margin-bottom: 20px; color: #d32f2f; font-size: 20px; font-weight: bold;">
            Nessun abbonamento attivo
          </div>
          <div style="margin-bottom: 15px; color: #424242; font-size: 16px;">
            Non hai un abbonamento attivo.
            <br>Sottoscrivi un piano per accedere a tutti i servizi.
          </div>`;
        break;

      case SUBSCRIPTION_STATUS.ERROR:
        messageTemplate += `
          <div style="margin-bottom: 20px; color: #d32f2f; font-size: 20px; font-weight: bold;">
            Errore durante la verifica
          </div>
          <div style="margin-bottom: 15px; color: #424242; font-size: 16px;">
            Si è verificato un errore durante la verifica del tuo abbonamento.
            <br>Per favore, riprova più tardi o contatta l'assistenza.
          </div>`;
        break;
    }

    // Aggiungi il pulsante di azione se necessario
    // if (response.details?.action) {
    //   const buttonText = response.details.action === "subscribe" ? "Sottoscrivi un Piano" : "Rinnova Abbonamento";
    //   messageTemplate += `
    //     <div style="margin-top: 20px;">
    //       <a href="/pricing" style="background-color: #2196f3; color: white; padding: 12px 24px;
    //         text-decoration: none; border-radius: 5px; font-weight: bold; display: inline-block;
    //         transition: background-color 0.3s;">
    //         ${buttonText}
    //       </a>
    //     </div>`;
    // }

    messageTemplate += "</div>";
    return messageTemplate;
  };

  const checkSubscriptionStatus = async () => {
    try {
      const response = await statusCheck(email);

      // Se l'abbonamento è scaduto o non esiste, effettua il logout
      if (
        !response.status &&
        (response.code === SUBSCRIPTION_STATUS.EXPIRED || response.code === SUBSCRIPTION_STATUS.NO_SUBSCRIPTION)
      ) {
        dispatch(logoutRoot());
        window.location.reload();
        return false;
      }

      // Se c'è un messaggio da mostrare (scadenza imminente o errore)
      if (response.code !== SUBSCRIPTION_STATUS.ACTIVE) {
        const messageTemplate = createSubscriptionMessage(response);
        console.log(messageTemplate);
        setCurrentMessage({
          id: "subscription-warning",
          title: response.message,
          message: messageTemplate,
          type: response.details.severity === "high" ? "error" : "warning",
          forceActive: true,
          html: messageTemplate,
        });
        setShowMessage(true);
      }

      return true;
    } catch (error) {
      console.error("Errore durante il controllo dell'abbonamento:", error);
      return false;
    }
  };
  useEffect(() => {
    if (email) {
      checkSubscriptionStatus();
    }
  }, [email]);

  useEffect(() => {
    // verifica connesione
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connesione sia assente o instabile",
      });
      return;
    }
    //______
    if (token) {
      stateChange();
      dispatch(errorReset());
      dispatch(fetchDayTotal(DataTranform(startDate)));
      // handleMessage();

      document.getElementById("input-text").focus(); // metto il puntatore sull'input

      if (!startIntroTour && startCaricaGrattaTour) {
        dispatch(homeTourStart());
      }
    }
  }, [dispatch, startCaricaGrattaTour, startDate, startIntroTour, token]);

  useEffect(() => {
    // verifica connessione
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    const checkStatus = async () => {
      if (token) {
        // Check subscription status
        const isSubscriptionValid = await checkSubscriptionStatus();
        if (!isSubscriptionValid) return;

        stateChange();
        dispatch(errorReset());

        // Se non c'è un messaggio di abbonamento, controlla gli altri messaggi
        if (!currentMessage?.id?.includes("subscription")) {
          await checkForMessages();
        }
      }
    };

    checkStatus();
  }, [dispatch, startDate, token, email]);

  useEffect(() => {
    const checkMessages = async () => {
      // First check subscription message
      if (subscriptionMessage) {
        setCurrentMessage(subscriptionMessage);
        setShowMessage(true);
        return;
      }

      // If no subscription warning, check regular messages
      await checkForMessages();
    };

    checkMessages();
  }, [subscriptionMessage]);

  const checkForMessages = async () => {
    try {
      // Verifica se l'utente ha già visto il messaggio
      const seenMessages = JSON.parse(localStorage.getItem("seenMessages") || "[]");

      // Recupera il messaggio dal database
      const messagesRef = collection(db, "message");
      const messageSnapshot = await getDocs(messagesRef);

      if (!messageSnapshot.empty) {
        const messages = [];
        messageSnapshot.forEach((doc) => {
          const messageData = doc.data();
          // Verifica se il messaggio è di test e se siamo in ambiente di sviluppo
          const isTestMessage = messageData.isTest && window.location.hostname === "localhost";
          // Verifica se il messaggio è attivo e (non è stato visto O è forceActive) e se non è un messaggio di test o se lo è ma siamo in localhost
          if (
            messageData.active &&
            (!seenMessages.includes(doc.id) || messageData.forceActive) &&
            (!messageData.isTest || isTestMessage)
          ) {
            messages.push({
              id: doc.id,
              ...messageData,
            });
          }
        });

        // Ordina i messaggi per data e priorità
        if (messages.length > 0) {
          const sortedMessages = messages.sort((a, b) => {
            // Prima ordina per forceActive (i messaggi forzati hanno priorità)
            if (a.forceActive && !b.forceActive) return -1;
            if (!a.forceActive && b.forceActive) return 1;
            // Poi per timestamp
            return (b.timestamp?.seconds || 0) - (a.timestamp?.seconds || 0);
          });
          setCurrentMessage(sortedMessages[0]);
          setShowMessage(true);
        }
      }
    } catch (error) {
      console.error("Errore nel recupero dei messaggi:", error);
    }
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const handleNeverShowAgain = () => {
    if (currentMessage) {
      const seenMessages = JSON.parse(localStorage.getItem("seenMessages") || "[]");
      seenMessages.push(currentMessage.id);
      localStorage.setItem("seenMessages", JSON.stringify(seenMessages));
    }
    setShowMessage(false);
  };

  if (!token) {
    return <Redirect to="/auth"></Redirect>;
  }

  window.onbeforeunload = function (e) {
    return false;
  };

  document.onkeydown = function (e) {
    e = e || window.event;
    var key = e.which || e.keyCode;
    // verifica connesione
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connesione sia assente o instabile",
      });
      return;
    }
    //-----
    if (key === 119) {
      if (uiVendita.length === 0) {
        audio.loop = false;
        audio.play();
        toast.error("Il tuo carrello è vuoto", {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }
      const date = daySelected.split("-");
      dispatch(incassa(uiVendita, DataTranform(new Date(date[2], +date[1] - 1, date[0]))));
    }
  };

  const handleMessage = async () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connesione sia assente o instabile",
      });
      return;
    }
    //-----
    const res = await checkMessage();
    if (res.display === true) {
      Swal.fire({
        title: res.title,
        icon: "info",
        html: res.message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    }
    document.getElementById("input-text").focus(); // metto il puntatore sull'input
  };

  const handleDateSelect = async (date) => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connesione sia assente o instabile",
      });
      return;
    }
    //-----
    setStartDate(date);
    const day = await date.toLocaleDateString().replaceAll("/", "-");
    dispatch(fetchDayTotal(DataTranform(date)));
    setDaySelected(day);
    setIsOpen(false);
    document.getElementById("input-text").focus(); // metto il puntatore sull'input
  };
  const rederDatePikers = (e) => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connesione sia assente o instabile",
      });
      return;
    }
    //-----
    if (uiVendita.length !== 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Prima di selezionare un altro giorno devi incassare o eliminare il carrello corrente",
      });
      return;
    }
    e.preventDefault();
    setIsOpen(true);
  };

  const handleScarica = (e) => {
    e.preventDefault();
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connesione sia assente o instabile",
      });
      return;
    }
    //-----

    if (input.trim() === "") {
      //sound
      audio.loop = false;
      audio.play();
      toast.error("non hai inserito nessun codice", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch(errorReset());
      setInput("");
      document.getElementById("input-text").focus(); // metto il puntatore sull'input
      return;
    }

    dispatch(scarica(input.slice(0, 14)));
    setInput("");

    document.getElementById("input-text").focus(); // metto il puntatore sull'input
    dispatch(homeSearchBarTourStart());
  };
  const deleteElement = (key, vUnita, nome) => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connesione sia assente o instabile",
      });
      return;
    }
    //-----
    dispatch(eliminaVendita(key, vUnita, nome));
    document.getElementById("input-text").focus(); // metto il puntatore sull'input
  };
  const handleCarrello = (e) => {
    e.preventDefault();
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connesione sia assente o instabile",
      });
      return;
    }
    //-----
    if (uiVendita.length === 0) {
      audio.loop = false;
      audio.play();
      toast.error("Il tuo carrello è vuoto", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      document.getElementById("input-text").focus(); // metto il puntatore sull'input
      return;
    }

    console.log("data-selezionata prima di scaricare", daySelected);
    const date = daySelected.split("-");
    dispatch(incassa(uiVendita, DataTranform(new Date(date[2], +date[1] - 1, date[0]))));

    document.getElementById("input-text").focus(); // metto il puntatore sull'input

    if (startStornoTour) {
      dispatch(afterstornoTourStart());
    } else {
      dispatch(incassaStart());
    }
  };

  const alterShow = () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connesione sia assente o instabile",
      });
      return;
    }
    //-----
    dispatch(errorReset());
  };

  const handelStorna = async () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connesione sia assente o instabile",
      });
      return;
    }
    //-----
    try {
      const { value: text } = await Swal.fire({
        title: "Vuoi stornare un biglietto ?",
        input: "text",
        inputLabel: "inserisci il codice del Gratta&Vinci che vuoi stornare",
        inputPlaceholder: "spara qui il codice",
        showCancelButton: true,
        cancelButtonColor: "red",
        confirmButtonColor: "green",
      });
      if (text) {
        // if (text.length !== 14) {
        //   toast.error("hai inserito un codice errato", {
        //     position: "top-left",
        //     autoClose: 2000,
        //     hideProgressBar: false,
        //     closeOnClick: false,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "colored",
        //   });
        //   return;
        // }

        dispatch(stornaBiglietto(text.slice(0, 14)));
        dispatch(stornoTourStart());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelMostraVendite = (e) => {
    e.preventDefault();
    const date = daySelected.split("-");
    dispatch(mostraVendite(DataTranform(new Date(date[2], +date[1] - 1, date[0]))));
    push({
      pathname: `/statistiche/${daySelected}`,
      state: { arrVenditeDay: { arrVenditeDay }, from: "home" },
    });
  };

  const handleScaricaMazzetta = async () => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connesione sia assente o instabile",
      });
      return;
    }
    //-----
    try {
      const { value: text } = await Swal.fire({
        title: "Vuoi scaricare un intera mazzetta ?",
        input: "text",
        inputLabel: "inserisci il codice di un Gratta&Vinci della mazzetta che vuoi scaricare",
        inputPlaceholder: "spara qui il codice",
        showCancelButton: true,
        cancelButtonColor: "red",
        confirmButtonColor: "green",
      });
      if (text) {
        dispatch(scaricaMazzetta(text.slice(0, 14)));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rederVendita = () => {
    return uiVendita.map((item) => {
      return (
        // <div className="single-product" key={item[0].key}>

        <div className="body-table tab-storno" key={item.key}>
          <h2 className="hd-nome">
            <img src={item.imgM} alt="" />
            {item.nome}
          </h2>

          <h2 className="hd-serieM"> {item.serieM}</h2>
          <h2 className="hd-progM">{item.progM}</h2>
          <h2 className="hd-vUnita">{item.vUnita}€</h2>
          <h2 className={item.nBiglietti > 10 ? "hd-giacenza" : "hd-giacenza-anime"}>{item.nBiglietti}</h2>
          <h2>
            <TiIcons.TiDelete className="icon-delete" onClick={() => deleteElement(item.key, item.vUnita, item.nome)} />
          </h2>
        </div>
      );
    });
  };

  return (
    <>
      {token ? <Navbar isAutenticated={token} /> : null}
      <div className="home">
        <div className="input-e-data-pikers">
          <div className="form-container">
            <form action="" onSubmit={handleScarica}>
              <div className="local-bootstrap">
                <div className="search">
                  <MdIcons.MdBarcodeReader className="icon-bar" id="icon-br" />
                  <BiBarcodeReader className="icon-bar" id="icon-brc" />
                  <input
                    type="text"
                    className="form-control input-icon-br"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    onChange={(e) => setInput(e.target.value)}
                    value={input.slice(0, 14)}
                    inputMode="none"
                    placeholder="Spara qui il codice del Gratta&Vinci che vuoi scaricare"
                    id="input-text"
                    autoComplete="off"
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="data-pikers">
            {isOpen ? (
              <DatePicker
                className="data-pikers-date"
                locale="it"
                selected={startDate}
                value={startDate}
                onChange={(date) => handleDateSelect(date)}
                dateFormat="dd-MM-yyyy"
                calendarClassName="data-calendar"
                inline
              />
            ) : (
              <button className="btn-date" onClick={rederDatePikers}>
                {startDate.toLocaleDateString()} <FaIcons.FaCalendarAlt className="icon-calendar" onClick={rederDatePikers} />
              </button>
            )}
          </div>
        </div>
        <div className="form-carrello">
          <form action="" onSubmit={handleCarrello}>
            <div className="table-container">
              <div className="head-carello">
                <h1 className="t-nome">Nome</h1>
                <h1 className="t-serie">Serie</h1>
                <h1 className="t-prog">Prg</h1>
                <h1 className="t-prezzo">Prezzo</h1>
                <h1 className="t-giacenza">Gz</h1>
                <h1>
                  <RiIcons.RiDeleteBin6Fill />
                </h1>
              </div>
              <div className="vendita-result">
                {loadingIncassa ? (
                  <div className="spinner-carrello">
                    <CarSpinner />
                  </div>
                ) : loading ? (
                  <SpinnerCarrello />
                ) : (
                  rederVendita()
                )}
                {/* {<SpinnerCarrello />} */}
              </div>
            </div>
          </form>
          <div className="container-totali">
            <div className="tot-car-container">
              <div className="titolo-carrello">
                <p>Totale carrello</p> <h1> {totCarrello}€ </h1>
              </div>
              <button id="btn-incassa" className="btn-carrello" onClick={handleCarrello}>
                Incassa
                <span>
                  <FiIcons.FiShoppingCart style={{ marginTop: "1vh" }} />
                </span>
                <span data-tip="Puoi Incassare il carrello <br />  anche premendo F8" data-delay-show="100">
                  &nbsp;&nbsp;&nbsp;(F8)
                </span>
                <ReactTooltip multiline={true} />
              </button>

              <div className="scarica-mazzetta-container">
                <button onClick={handleScaricaMazzetta}>
                  Scarica Mazzetta
                  <span>
                    <MdIcons.MdOutlineDownloadDone />
                  </span>
                </button>
              </div>
              <div className="storno-container storno">
                <button onClick={handelStorna}>
                  Storna biglietto
                  <span>
                    <RiIcons.RiDeleteBackFill />
                  </span>
                </button>
              </div>
              <div className="storno-container mostra">
                <button onClick={handelMostraVendite}>
                  Mostra Vendite
                  <span>
                    <RiIcons.RiPrinterLine />
                  </span>
                </button>
              </div>
              {/* totale giornaliero */}
              <div className="tot-giorno-container">
                <p>Totale giornaliero</p>
                {loadingIncassa ? <SpinnerTotG /> : <h1> {totaleGiornaliero}€ </h1>}
              </div>
            </div>
          </div>
        </div>
        {error ? (playError(), notifyError(), alterShow()) : null}

        <MessageAlert
          message={currentMessage}
          open={showMessage}
          onClose={handleCloseMessage}
          onNeverShowAgain={subscriptionMessage ? undefined : handleNeverShowAgain}
        />

        <ToastContainer
          position="top-letf"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}

export default Home;
