import React from "react";
import "./PlanCard.css";

function PlanCard({ item }) {
  return (
    <div key={item.path} className="card m-3 shadow-lg plan-card" style={{ width: "24rem" }}>
      <div className="title-pc bold-price-fm pt-4">{item.title}</div>
      <div className="risparmio-pc bold-price-fm">{item.risparmio}</div>
      <div className="price-pc bold-price-fm">{item.price}</div>
      {item.title === "StartUp" ? null : (
        <div className="sconto-pc bold-price-fm">
          <p>Scontato da</p> &nbsp;
          <s className="t-pc bold-price-fm">{item.priceFull}</s> &nbsp;
          <p>IVA Incl.</p>
        </div>
      )}
      <img className="img-fluid p-2 img-fm" src={item.img} alt="" />

      <form
        // action={`${process.env.REACT_APP_MY_DOMAIN_SERVER}/create-checkout-session`}
        action={`https://server-easyscratch.oa.r.appspot.com/create-checkout-session`}
        method="POST"
        className="btn-link"
      >
        <button className="btn btn-primary btn-pc bold-price-fm">ACQUISTA</button>
        <input type="hidden" value={item.link} name="link" id="link" />
        <input type="hidden" value={item.path} name="path" id="path" />
        <input type="hidden" value={item.title} name="title" id="title" />
        <input type="hidden" value={item.type} name="type" id="type" />
      </form>

      <div className="head-pc">
        <div className="description-pc text-price-fm">{item.description}</div>
        <div className="specific-pc">
          {item.specific.map((s, index) => (
            <div key={index} className="row-spec text-price-fm">
              {s.ico}
              &nbsp;
              {s.text}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PlanCard;
